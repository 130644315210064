import React from 'react'
import { Link } from 'gatsby'

import YouTube   from '../../assets/icons/YouTube.svg'
import Instagram from '../../assets/icons/Instagram.svg'

export default class AuthorItem extends React.Component {
  constructor() {
    super()
  }

  renderSocialAccount() {
    return (
      <a target='_blank' href={this.props.socialAccountURL} className='socialAccountLink'>
        {this.props.socialAccount == 'YouTube'   ? <img src={YouTube}   alt={this.props.socialAccount}/> : null}
        {this.props.socialAccount == 'Instagram' ? <img src={Instagram} alt={this.props.socialAccount}/> : null}
        <span>{this.props.subscribers}</span>
      </a>
    )
  }

  render() {
    return (
      <div className='authorItem'>
        <div
          className='authorItemImage'
          style = {{ backgroundImage: `url(${this.props.userpic.publicURL})` }}
        >
        </div>

        <h4>{this.props.name}</h4>
        <h4 className='description'>{this.props.description}</h4>

        {/* this.props.socialAccountURL ? this.renderSocialAccount() : null */}
      </div>
    )
  }
}
