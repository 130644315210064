import React from 'react'
import { graphql, navigate } from 'gatsby'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import MetaData    from '../components/atoms/MetaData.js'
import RichBlock   from '../components/RichBlock'
import MenuBar     from '../components/MenuBar'
import Footer      from '../components/Footer'
import AuthorItem  from '../components/atoms/AuthorItem.js'

export default class Team extends React.Component {
  constructor() {
    super()

    this.togglePageScroll = this.togglePageScroll.bind(this)
    this.targetElement = null

    this.state = {
      scroll: true
    }
  }

  componentDidMount() {
    this.targetElement = document.querySelector('.page')
  }

  componentDidUpdate(prevProps, prevState) {
    // Disable Page Scroll
    if(prevState.scroll != this.state.scroll) {
      if(this.state.scroll) {
        enableBodyScroll(this.targetElement)
      } else {
        disableBodyScroll(this.targetElement)
      }
    }
  }

  togglePageScroll() {
    this.setState({
      scroll: !this.state.scroll
    })
  }

  renderAuthorItems(group) {
    let authorsElements = []

    const authors = this.props.data.allAuthorsJson.nodes

    authors.map(function(author, i) {
      if(author.group === group) {
        authorsElements.push(
          <AuthorItem
          {...author}
          key = { i }
          />
        )
      }

      return null
    })

    return authorsElements
  }

  render() {
    return(
      <div className='app-wrapper'>
        <MetaData
          title       = 'Wylsacom Media — Команда'
          description = 'Wylsacom Media — это команда техногиков. Вместе мы делаем контент чаще и лучше.'
        />

        <MenuBar
          items    = {MenuBarItems}
          internal = 'команда'
          action   = {this.togglePageScroll}
        />

        <RichBlock
          id   = 'main'
          text = 'Лица канала'
        />

        <div id='channelAuthors' className='authorsGrid-container large'>
          {this.renderAuthorItems(1)}
        </div>

        <RichBlock
          id   = 'ads'
          text = 'Реклама и&nbsp;продакшн'
        />

        <div id='productionAuthors' className='authorsGrid-container small'>
          {this.renderAuthorItems(2)}
        </div>

        <RichBlock
          id   = 'media'
          text = 'Онлайн-медиа'
        />

        <div id='mediaAuthors' className='authorsGrid-container small'>
          {this.renderAuthorItems(3)}
        </div>

        <Footer />
      </div>
    )
  }
}

export const TeamData = graphql`
  {
    allAuthorsJson(sort: {fields: position}) {
      nodes {
        id
        name
        description
        socialAccount
        socialAccountURL
        subscribers
        userpic {
          publicURL
        }
        group
      }
    }
  }
`

const MenuBarItems = [
  {
    title: 'связаться',
    to: '#contact',
    external: false
  },
  {
    title: 'цены',
    to: 'https://docs.google.com/spreadsheets/d/1fOcAeps0CaBygkR9bmPinpkZ2LBYEzO_PJkYkfMKLrg',
    external: true
  }
]
